/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  height: 82px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  background: var(--SJF-bg);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  font-family: var(--font-1);
}

#header .logo h1 {
  font-size: 30px;
  margin: 0;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 2px;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #7a6960;
  text-decoration: none;
}

#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 76px;
}

#main {
  margin-top: 82px;
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/**
  * Desktop Navigation 
  */
  .mobile ul{
    display: none;
  }
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  color: #4e4039;
  white-space: nowrap;
  transition: 0.3s;
  font-weight: 600;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: var(--SJF);
}

.navbar .getstarted {
  background: var(--SJF);
  color: #fff;
  padding: 10px 25px;
  margin-left: 30px;
  border-radius: 50px;
  font-weight: 700;
  font-family: var(--font-2);
}

.navbar .getstarted:focus {
  padding: 10px 25px;
  color: #fff;
}

.navbar .getstarted:hover {
  color: #fff;
  background: var(--SJF-hover);
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 15px;
  text-transform: none;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: var(--SJF);
}

.navbar .dropdown:not(:hover)>ul {
  pointer-events: none;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
 
}


/**
  * Mobile Navigation 
  */
.mobile-nav-toggle {
  color: #7a6960;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
    position: relative;
    z-index: 9999;
  }

  .navbar ul {
    display:none;
  }
}
.mobile{
  display: none;
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(78, 64, 57, 0.9);
  transition: 0.3s;
}
.mobile a{
  text-decoration: none;
  color: #fff;
}
.mobile ul{
  width: 64%;
  margin: auto;
}
.mobile ul li{
  list-style: none;
}
 /* .mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(78, 64, 57, 0.9);
  transition: 0.3s;
} */

.mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;

}

.mobile ul {
  display: block;
  position: absolute;
  top: 80px;
  right: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  color: black;
  transition: 0.3s;
  text-align: center;
  padding-bottom: 20px;
}

.mobile a {
  padding: 10px 20px;
  font-size: 15px;
  color: #7a6960;
}

.mobile a:hover,
.mobile .active,
.mobile li:hover>a {
  color: var(--SJF);
}

.mobile .getstarted {
  margin: 15px;
  background: var(--SJF);
  color: #fff;
  padding: 10px 25px;
  margin-left: 30px;
  border-radius: 50px;
  font-weight: 700;
  font-family: var(--font-2);
}

.mobile .dropdown ul {
  position: static;
  /* display: none; */
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.mobile .dropdown ul li {
  min-width: 200px;
}

.mobile .dropdown ul a {
  padding: 10px 20px;
}

.mobile .dropdown ul a i {
  font-size: 12px;
}

.mobile .dropdown ul a:hover,
.mobile .dropdown ul .active:hover,
.mobile .dropdown ul li:hover>a {
  color: var(--SJF);
}
/* .dropdown .who:hover ul{
  display: none;
} */
/* .mobile .dropdown>.dropdown-active {
  display: block;
}  */

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  position: relative;
  padding: 0px 0px 0px 0px;
  color: #212529;
  font-size: 15px;
  margin-top: 80px;
  z-index: 1;
  font-family: var(--font-1);
}

#footer::after {
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  background: var(--SJF);
  z-index: -1;
  opacity: 0.94;
}

#footer p {
  color: #fff;
}

#footer .footer-newsletter {
  padding: 30px 0px;
  background: -webkit-linear-gradient(90deg, rgb(0, 83, 149), rgb(122, 204, 200));
  background: linear-gradient(90deg, rgb(0, 83, 149), rgb(122, 204, 200));
  text-align: center;
  font-size: 15px;
  color: #fff;
  width: 75%;
  margin: 0px auto;
  border-radius: 10px;
  transform: translate(0px, -50%);
  box-shadow: 0 0.4rem 1rem -0.01rem rgb(0 0 0 / 10%), 0 0.4rem 1rem -0.01rem rgb(0 0 0 / 15%);
}

#footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0px;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  color: #ffffff;
}

#footer .footer-newsletter .mailr {
  margin-top: 5px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  text-align: left;
}

#footer .footer-newsletter .mailr input[type="email"] {
  border: 0;
  outline: none;
  padding: 4px 4px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter .mailr input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: var(--SJF);
  color: #fff;
  transition: 0.3s;
  border-radius: 4px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter .mailr input[type="submit"]:hover {
  background: var(--SJF-hover);
}

#footer .footer-top {
  padding: 0px 0 30px 0;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h4 {
  font-size: 22px;
  margin: 0 0 30px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-contact p {
  line-height: 24px;
  margin-bottom: 0;
  font-family: var(--font-1);
  color: #ffffff;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: var(--SJF-yellow);
  font-size: 16px;
  line-height: 1;
  position: relative;
  top: -1px;
  left: -2px;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #ffffff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: var(--SJF-light);
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: var(--SJF);
  color: #fff;
  line-height: 1;
  padding: 10px 0px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links {
  display: flex;
  align-items: center;
  gap: 15px
}

#footer .footer-top .social-links a:hover {
  background: var(--SJF-yellow);
  color: var(--SJF);
  text-decoration: none;
}

#footer .copyright {
  text-align: left;
}

#footer .credits {
  float: right;
  text-align: center;
  font-size: 13px;
  color: #212529;
}

#footer .credits a {
  color: var(--SJF);
}

@media (max-width: 575px) {

  #footer .copyright,
  #footer .credits {
    float: none;
    -moz-text-align-last: center;
    text-align-last: center;
    padding: 3px 0;
  }

  #footer .footer-newsletter {
    width: 95%;
  }

  .footer-contact {
    text-align: center;
  }
}
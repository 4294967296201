/*--------------------------------------------------------------
# Awareness Page
--------------------------------------------------------------*/
.awarenessBlog {
    background: #f4fdff;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px;
    margin: 10px;
    margin-bottom: 30px;
    transition: 400ms;
    border-bottom: 6px solid transparent;
    box-shadow: 0 0 20px #3a3a3a29;
}


    .awarenessBlog:hover {
        transform: scale(1.02) translateY(-10px);
        border-bottom: 6px solid var(--SJF);
        box-shadow: 0 0 20px #3a3a3a4d;
    }

    .awarenessBlog ul {
        list-style: none;
        margin: 0px;
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: var(--SJF);
    }

        .awarenessBlog ul li {
            font-size: 14px;
            flex: 1;
        }

            .awarenessBlog ul li:last-child {
                text-align: right;
            }

            .awarenessBlog ul li i {
                padding-right: 6px;
            }

    .awarenessBlog h4 {
        font-size: 20px;
        font-weight: 800;
        padding: 0px 15px 0px;
    }

    .awarenessBlog p {
        padding: 0px 15px 0px;
    }

        .awarenessBlog p::after {
            content: '....';
        }

    .awarenessBlog .readMore {
        padding: 6px 12px;
        margin: 0px 15px 20px;
        background: var(--SJF);
        border-radius: 30px;
        color: #fff;
        font-weight: 600;
        font-size: 14px;
    }

        .awarenessBlog .readMore:hover i {
            padding-left: 4px;
            transition: 400ms;
        }

        .awarenessBlog .readMore i {
            font-weight: normal;
        }

/*--------------------------------------------------------------
# Awareness Inner Page
--------------------------------------------------------------*/
.awarenessBanner {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    height: 50vh;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

    .awarenessBanner::after {
        content: '';
        position: absolute;
        top: 0px;
        bottom: 0px;
        height: 100%;
        width: 100%;
        background: var(--SJF-dark);
        z-index: -1;
        opacity: 0.85;
        pointer-events: none;
    }

    .awarenessBanner h2 {
        font-weight: 800;
        color: #fff;
        margin: 0px 0px 50px;
        font-size: 40px;
        letter-spacing: 2px;
    }

.awarenessList {
    list-style: none;
    padding: 0px;
    margin: 0 0 10px;
}

    .awarenessList li {
        position: relative;
        padding: 4px 0px 4px 25px;
    }

        .awarenessList li::before {
            position: absolute;
            left: 0px;
            font-family: Icofont;
            content: "\ea98";
            color: var(--SJF);
        }

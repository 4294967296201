@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500;600;700&display=swap");
@import url("helpers.css");
@import url("header-footer.css");

/* font-family: 'Nunito', sans-serif; */
/* font-family: 'Josefin Sans', sans-serif; */
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  background: #f0fffe;
  border-bottom: 2px solid #e5f3ff;
  margin: 72px 0 -72px 0;
}

#hero h1 {
  margin: 0;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.2;
  color: #4e4039;
}

#hero h2 {
  color: #a08f86;
  margin: 15px 0 0 0;
  font-size: 24px;
}

#hero .btn-get-started {
  font-family: var(--font-2);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 3px;
  transition: 0.5s;
  margin-top: 30px;
  color: #fff;
  background: var(--SJF);
}

#hero .btn-get-started:hover {
  background: var(--SJF-hover);
}

#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (max-width: 991px) {
  #hero {
    height: calc(100vh - 82px);
  }

  #hero .animated {
    -webkit-animation: none;
    animation: none;
  }

  #hero .hero-img {
    text-align: center;
  }

  #hero .hero-img img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
  }

  #hero .hero-img img {
    width: 70%;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }
}

@media (max-height: 600px) {
  #hero {
    height: calc(120vh);
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #fef8f5;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 0;
  line-height: 1px;
  margin-bottom: 15px;
  color: #c2b7b1;
}

.section-title p {
  padding-bottom: 15px;
  margin-bottom: 15px;
  position: relative;
  font-size: 32px;
  font-weight: 700;
  color: #4e4039;
}

.section-title p::after {
  content: "";
  position: absolute;
  display: block;
  width: 60px;
  height: 2px;
  background: var(--SJF);
  bottom: 0;
  left: calc(50% - 30px);
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background-color: var(--SJF-light);
  color: var(--SJF);
  min-height: 40px;
  font-family: var(--font-1);
}

.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 800;
  margin: 0px;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6c757d;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# Activities
--------------------------------------------------------------*/
.glightbox-clean .gslide-description {
  background: transparent !important;
}

.glightbox-clean .gdesc-inner {
  padding: 20px 10px !important;
  color: #fff;
  text-align: center;
  font-size: 20px;
}

.activityDescribe {
  position: relative;
  clear: both;
}

.activityDescribe h3 {
  font-size: 26px;
  font-weight: 700;
  border-bottom: 2px solid #ccc;
  margin-bottom: 15px;
  padding: 0px 0px 6px;
}

.activityDescribe span.dte {
  position: absolute;
  right: 0px;
  bottom: 4px;
  font-weight: 700;
  font-size: 16px;
}

.activityDescribe~p.captn {
  margin-bottom: 30px;
  text-align: center;
}

.Activities>.container>.row>.col-md-12 {
  margin-top: 60px;
}

.Activities>.container>.row>.col-md-12:first-child {
  margin-top: 0px;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
  background: #eaf6ff;
  box-shadow: 0px 80px 0 #eaf6ff;
}

.sector-hlght {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 100px 30px 0px;
  padding: 20px;
  border-radius: 10px;
  font-family: var(--font-2);
  box-shadow: 0 0 18px #3a3a3a30;
}

.sector-hlght span {
  margin-top: -25%;
  padding: 20px;
  border-radius: 80px;
}

.sector-hlght span img {
  width: 80px;
}

.sector-hlght h4 {
  margin-top: 10px;
  margin-bottom: 12px;
  font-weight: 600;
  color: #5f5f5f;
}

.sector-hlght p {
  text-align: justify;
  font-size: 15px;
  color: #868686;
  font-family: var(--font-1) !important;
}

@media (max-width: 575px) {
  .about {
    background: #eaf6ff;
    padding: 20px 0px 60px;
  }

  .sector-hlght {
    margin: 50px 15px 0px;
    padding: 20px;
  }
}

/*--------------------------------------------------------------*/
.about h3 {
  font-weight: 700;
  font-size: 34px;
  color: #4e4039;
  font-family: var(--font-1) !important;
}

/* .about h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
  color: #7a6960;
} */

/* .about i {
  font-size: 48px;
  margin-top: 15px;
  color: var(--SJF);
} */

.about p {
  font-size: 18px;
  font-family: var(--font-1);
  color: #5a6570;
  text-align: justify;
}

@media (max-width: 991px) {
  .about .about-img img {
    max-width: 70%;
  }
}

@media (max-width: 767px) {
  .about .about-img img {
    max-width: 90%;
  }
}

span.level {
  position: relative;
  font-size: 22px;
  letter-spacing: 1px;
  font-weight: 900;
  line-height: 2;
  padding: 8px 0px;
  border-top: 2px solid #ababab;
  border-bottom: 2px solid #ababab;
  text-transform: uppercase;
}

.member {
  background: #ffffff;
  border-radius: 10px;
  text-align: center;
  padding: 15px 10px;
  box-shadow: 0 0 18px #3a3a3a30;
}

.member .picWrp {
  text-align: center;
}

.member .picWrp img {
  width: 60%;
  margin: auto;
}

.member h5 {
  margin-top: 16px;
  font-weight: 800;
}

.member span {
  font-size: 16px;
}

.Team {
  position: relative;
}

.Team::after {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 400px;
  width: 500px;
  content: "";
  background: url(../img/logo-4X4.png) no-repeat center center;
  background-size: 375px;
  z-index: -1;
  opacity: 0.2;
}

@media (max-width: 575px) {
  .member {
    margin: 15px 30px;
  }

  span.level {
    font-size: 14px;
  }
}

/*--------------------------------------------------------------
# Contribution
--------------------------------------------------------------*/
.contribution {
  background-color: #fef8f5;
  padding: 80px 0px;
}

.contribution .cont-left h3 {
  font-weight: 800;
  color: #525252;
  color: var(--SJF);
  margin-bottom: 15px;
}

.contribution .cont-left p {
  margin: 0px;
  font-size: 18px;
  text-align: justify;
}

.contribution .icon-box {
  padding: 45px 50px;
  position: relative;
  overflow: hidden;
  margin: 0px;
  background: #fff;
  box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
  transition: all 0.3s ease-in-out;
  border-radius: 15px;
  text-align: center;
  border: 5px inset var(--SJF-yellow);
  box-shadow: inset -30px -2px 30px rgb(64 82 128 / 10%), inset 30px 2px 30px rgb(255 255 255 / 80%);
}

.contribution .title {
  font-weight: 800;
  margin-bottom: 15px;
  font-size: 26px;
  color: #616161;
  letter-spacing: 4px;
}

.contribution .description {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 0;
  color: #9a9a9a;
}

.contribution-carousel .owl-dots {
  text-align: center;
}

.contribution-carousel .owl-dots .owl-dot {
  background: #cdcdcd !important;
  height: 6px;
  width: 30px;
  border-radius: 20px;
  margin: 0px 10px;
  outline: none;
}

.contribution-carousel .owl-dots .owl-dot span {
  display: none !important;
}

.contribution-carousel .owl-dots .owl-dot.active {
  background: var(--SJF) !important;
}

@media (max-width: 575px) {
  .contribution-carousel {
    margin-top: 50px;
  }

  .contribution {
    padding: 60px 0px;
  }

  .contribution .cont-left h3 {
    text-align: center;
  }
}

/*--------------------------------------------------------------
# Contribution
--------------------------------------------------------------*/
.stories {
  background: #fff;
}

.storyline {
  background: #009688;
  border-radius: 15px;
  color: #fff;
  overflow: hidden;
}

.storyline .story-img {
  width: 100%;
  height: 450px;
  display: block;
}

.storyline .story-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.storycont {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 40px 20px 40px 40px;
}

.storycont h4 {
  font-weight: 800;
  font-size: 26px;
  margin-bottom: 15px;
}

.storycont p {
  font-family: var(--font-1);
  font-size: 18px;
  text-align: justify;
}

.storycont a.btn {
  background: var(--SJF-yellow);
  color: #676666;
  padding: 6px 20px;
  font-size: 14px;
  margin-left: 0px;
  border-radius: 50px;
  font-weight: 800;
  font-family: var(--font-2);
}

@media (max-width: 575px) {
  .storycont {
    padding: 20px;
  }

  .storycont h4 {
    font-size: 22px;
    text-align: center;
  }

  .storycont a.btn {
    margin: auto;
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  margin: 0 0 40px 0;
  background: #fff;
  box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
  transition: all 0.3s ease-in-out;
  border-radius: 15px;
  text-align: center;
  border-bottom: 3px solid #fff;
}

.services .icon-box:hover {
  transform: translateY(-5px);
  border-color: var(--SJF-hover);
}

.services .icon i {
  font-size: 48px;
  line-height: 1;
  margin-bottom: 15px;
  color: var(--SJF-hover);
}

.services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
  /* portfolio-item */
}

.services .title a {
  color: #111;
}

.services .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Gallery
--------------------------------------------------------------*/
.gallery .gallery-item {
  margin-bottom: 30px;
}

.gallery #gallery-flters {
  padding: 0;
  margin: 0 0 35px 0;
  list-style: none;
  text-align: center;
}

.gallery #gallery-flters li {
  cursor: pointer;
  margin: 0 15px 15px 0;
  display: inline-block;
  padding: 5px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #212529;
  margin-bottom: 5px;
  user-select: none;
  transition: all 0.3s ease-in-out;
}

.gallery #gallery-flters li:hover,
.gallery #gallery-flters li.filter-active {
  color: var(--SJF);
}

.gallery #gallery-flters li:last-child {
  margin-right: 0;
}

.gallery .gallery-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  z-index: 1;
}

.gallery .gallery-wrap::before {
  content: "";
  background: rgba(122, 105, 96, 0.6);
  position: absolute;
  left: 100%;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
}

.gallery .gallery-wrap img {
  transition: 0.3s;
  position: relative;
  z-index: 1;
}

.gallery-lightbox {
  width: 100%;
  height: 260px;
  display: block;
}

.gallery-lightbox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ======================================= */

/* .gallery [data-fancybox=gallery] {} */

.gallery [data-fancybox=gallery] img {
  max-width: 100%;
  height: auto;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid var(--SJF);
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--SJF);
}

.portfolio-details .gallery-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(122, 105, 96, 0.08);
}

.portfolio-details .gallery-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .gallery-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .gallery-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# 80G
--------------------------------------------------------------*/
.donation80G-Wrp {
  background: #f4fdff;
  border-top: 2px solid #e5e5e5;
  border-bottom: 2px solid #e5e5e5;
  padding: 40px 0px;
}

.donation80G {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.donation80G img {
  width: 50px;
  margin-right: 30px;
}

.donation80G span {
  font-size: 15px;
  padding-right: 30px;
}

/*--------------------------------------------------------------
# F.A.Q
--------------------------------------------------------------*/
.faq {
  padding: 60px 0;
}

.faq .faq-list {
  padding: 0;
  list-style: none;
}

.faq .faq-list li {
  border-bottom: 1px solid #eae7e5;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.faq .faq-list .question {
  display: block;
  position: relative;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  font-weight: 600;
  padding-left: 25px;
  cursor: pointer;
  color: #c54811;
  transition: 0.3s;
}

.faq .faq-list i {
  font-size: 16px;
  position: absolute;
  left: 0;
  top: -2px;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 25px;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list .collapsed {
  color: #343a40;
}

.faq .faq-list .collapsed:hover {
  color: var(--SJF);
}

.faq .faq-list .collapsed .icon-show {
  display: inline-block;
  transition: 0.6s;
}

.faq .faq-list .collapsed .icon-close {
  display: none;
  transition: 0.6s;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {
  background: #fff;
  padding: 60px 0;
}

.team .member {
  text-align: center;
  margin-bottom: 20px;
  background: #343a40;
  position: relative;
  overflow: hidden;
}

.team .member .member-info {
  opacity: 0;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.2s;
}

.team .member .member-info-content {
  position: absolute;
  left: 50px;
  right: 0;
  bottom: 0;
  transition: bottom 0.4s;
}

.team .member .member-info-content h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
  color: #fff;
}

.team .member .member-info-content span {
  font-style: italic;
  display: block;
  font-size: 13px;
  color: #fff;
}

.team .member .social {
  position: absolute;
  left: -50px;
  top: 0;
  bottom: 0;
  width: 50px;
  transition: left ease-in-out 0.3s;
  background: rgba(78, 64, 57, 0.6);
  text-align: center;
}

.team .member .social a {
  transition: color 0.3s;
  display: block;
  color: #fff;
  margin-top: 15px;
}

.team .member .social a:hover {
  color: var(--SJF);
}

.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

.team .member:hover .member-info {
  background: linear-gradient(0deg, rgba(78, 64, 57, 0.95) 0%, rgba(78, 64, 57, 0.95) 15%, rgba(255, 255, 255, 0) 100%);
  opacity: 1;
  transition: 0.4s;
}

.team .member:hover .member-info-content {
  bottom: 30px;
  transition: bottom 0.4s;
}

.team .member:hover .social {
  left: 0;
  transition: left ease-in-out 0.3s;
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients .clients-slider .swiper-slide img {
  opacity: 0.5;
  transition: 0.3s;
}

.clients .clients-slider .swiper-slide img:hover {
  opacity: 1;
}

.clients .clients-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.clients .clients-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid var(--SJF);
}

.clients .clients-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--SJF);
}

/*--------------------------------------------------------------
# Contact Us
--------------------------------------------------------------*/
.contact .info {
  border-top: 3px solid var(--SJF);
  border-bottom: 3px solid var(--SJF);
  padding: 30px;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  padding: 20px 30px;
}

.contact .info iframe {
  border: 0;
  width: 100%;
  height: 100%;
}

.contact .info i {
  font-size: 20px;
  color: var(--SJF);
  float: left;
  width: 44px;
  height: 44px;
  background: var(--SJF-light);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #7a6960;
}

.contact .info p,
.contact .info a {
  padding: 0 0 10px 60px;
  font-size: 14px;
  color: #ab9d95;
  display: block;
  margin-bottom: 10px;
}

.contact .info h5 {
  font-size: 22px;
  font-weight: 700;
  color: var(--SJF);
  border-bottom: 2px solid var(--SJF);
  margin-bottom: 25px;
}

.contact .info .email p {
  padding-top: 5px;
}

.contact .info .social-links {
  padding-left: 60px;
}

.contact .info .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #333;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  margin-right: 10px;
}

.contact .info .social-links a:hover {
  background: var(--SJF);
  color: #fff;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: var(--SJF);
  color: #fff;
}

.contact .contactUs-form {
  width: 100%;
  border-top: 3px solid var(--SJF);
  border-bottom: 3px solid var(--SJF);
  padding: 30px;
  background: #fff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact .contactUs-form .form-group {
  padding-bottom: 8px;
}

.contact .contactUs-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .contactUs-form .error-message br+br {
  margin-top: 25px;
}

.contact .contactUs-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .contactUs-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .contactUs-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .contactUs-form input,
.contact .contactUs-form textarea,
.contact .contactUs-form select {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .contactUs-form label {
  font-weight: 600;
  font-size: 14px;
}

/* .contact .contactUs-form input,
.contact .contactUs-form select {
  height: 44px;
} */

.contact .contactUs-form textarea {
  padding: 10px 12px;
}

.contact .contactUs-form .heading {
  color: var(--SJF);
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 20px;
  display: block;
}

.contact .contactUs-form .donate-btn {
  background: var(--SJF);
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .contactUs-form .donate-btn:hover {
  background: var(--SJF-hover);
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
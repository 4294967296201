/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: 0 0;
  position: absolute;
}

*::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background: #dadada;
}

:root {
  --SJF: #005395;
  --SJF-hover: #0b6fc2;
  --SJF-dark: #012f48;
  --SJF-bg: #7accc8;
  --SJF-light: #cde9f8;
  --SJF-yellow: #ffea24;
  --bg: #fff;
  --fg: #17181c;
  --font-1: "Josefin Sans", sans-serif;
  --font-2: "Nunito", sans-serif;
}

.SJF {
  color: var(--SJF);
}

.SJF-hover {
  color: var(--SJF-hover);
}

.SJF-dark {
  color: var(--SJF-dark);
}

.SJF-bg {
  color: var(--SJF-bg);
}

.SJF-light {
  color: var(--SJF-light);
}

.SJF-yellow {
  color: var(--SJF-yellow);
}

.inr::before {
  content: "₹ ";
}

body {
  font-family: var(--font-2);
  color: #212529;
}

a {
  color: var(--SJF-dark);
  text-decoration: none;
}

a:hover {
  color: var(--SJF);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-primary {
  font-family: var(--font-2);
}

/*--------------------------------------------------------------
# Dark Mode Theme
--------------------------------------------------------------*/
@media (prefers-color-scheme: dark) {
  :root {
    --bg: #17181c;
    --fg: #e3e4e8;
  }
}

/*--------------------------------------------------------------
# Selection
--------------------------------------------------------------*/
:-moz-selection {
  background: var(--SJF);
  color: #fff !important;
}

::-webkit-selection {
  background: var(--SJF);
  color: #fff !important;
}

::-o-selection {
  background: var(--SJF);
  color: #fff !important;
}

::-ms-selection {
  background: var(--SJF);
  color: #fff !important;
}

::selection {
  background: var(--SJF);
  color: #fff !important;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: var(--SJF);
  border: 2px solid var(--SJF-yellow);
  outline: 2px solid var(--SJF);
  width: 35px;
  height: 35px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: var(--SJF-yellow);
  line-height: 0;
}

.back-to-top.active {
  visibility: visible;
  opacity: 0.8;
}

.back-to-top:hover {
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}
